.analytics-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    background: #fff;
    height: auto;
    overflow: scroll;
  }
  
  .chart-item {
    margin: 10px;
    width: 100%;
    max-width: 700px;
    height: 500px;
  }
  
  @media (min-width: 600px) {
    .analytics-container {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      
    }
  
    .chart-item {
      margin: 10px;
      flex: 1 1 calc(50% - 20px); /* Two items per row */
    }
  }
  
  @media (min-width: 900px) {
    .chart-item {
      flex: 1 1 calc(33.33% - 20px); /* Three items per row */
    }
  }
  