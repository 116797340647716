.chart-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #232B5D;
    padding: 16px;
    border-radius: 10px;
  }
  
  h2 {
    color: white;
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0;
    text-align: center;
  }
  
  .legend-container {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
  }
  
  .legend-item {
    display: flex;
    align-items: center;
    margin: 0 10px;
  }
  
  .legend-color {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin-right: 5px;
  }
  
  .legend-text {
    color: white;
    font-size: 14px;
  }
  