@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

html,body {
  margin: 0;
  padding:0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-color: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a.custom-button { 
	display:inline-block;
	padding:0.3em 1.2em;
	margin:0 0.1em 0.1em 0;
	border:0.16em solid rgba(255,255,255,0);
	border-radius:2em;
	box-sizing: border-box;
	text-decoration:none;
	font-family:'Roboto',sans-serif;
	font-weight:300;
	color:#FFFFFF;
	text-shadow: 0 0.04em 0.04em rgba(0,0,0,0.35);
	text-align:center;
	transition: all 0.2s;	
}

a.custom-button:hover { 
	border-color: rgba(255,255,255,1);
}

@media all and (max-width:30em){
 a.custom-button{
	display:block;
	margin:0.2em auto;
	}
}

.button7{
	display:inline-block;
    padding:0.7em 1.7em;
    margin:0 0.3em 0.3em 0;
    border-radius:0.2em;
    box-sizing: border-box;
    text-decoration:none;
    font-family:'Roboto',sans-serif;
    font-weight:400;
    color:#FFFFFF;
    background-color:#3369ff;
    box-shadow:inset 0 -0.6em 1em -0.35em rgba(0,0,0,0.17),inset 0 0.6em 2em -0.3em rgba(255,255,255,0.15),inset 0 0 0em 0.05em rgba(255,255,255,0.12);
    text-align:center;
    position:relative;
}
.button7:active{
    box-shadow:inset 0 0.6em 2em -0.3em rgba(0,0,0,0.15),inset 0 0 0em 0.05em rgba(255,255,255,0.12);
}
@media all and (max-width:30em){
    .button7{
        display:block;
        margin:0.4em auto;
    }
}

.bouncy{
	animation:bouncy 5s infinite linear;
	position:relative;
}
@keyframes bouncy {
	0%{top:0em}
	40%{top:0em}
	43%{top:-0.9em}
	46%{top:0em}
	48%{top:-0.4em}
	50%{top:0em}
	100%{top:0em;}
}

.social
{
	text-align: center;
	background-color: black;
}

.Signup-container{
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	

}

.Signup{

	width:400px;
	height:400px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-content: center;
	
	border: #888888 solid 1px;
	background-color: #f4f4f4;

}

@media(min-width:700px){
    .main-text{
      font-size: 5em;
    }
}

@media(max-width:700px){
    .main-text{
      font-size: 3em;
    }
}

.main-text{
	font-size: 10em;
}

.glow-on-hover {
    width: 220px;
    height: 40px;
    border: none;
    outline: none;
    color: black;
    background: #fff;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
}

.glow-on-hover:before {
    content: '';
    background: linear-gradient(45deg, transparent, transparent, transparent, #fff, #fff, #fff);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

.glow-on-hover:active {
    color: #000
}

.glow-on-hover:active:after {
    background: transparent;
}

.glow-on-hover:before {
    opacity: 1;
}

.glow-on-hover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #fff;
    left: 0;
    top: 0;
    border-radius: 5px;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}


.login-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 40px;
  background: rgba(0,0,0,.5);
}

.login-box h2 {
  margin: 0 0 30px;
  padding: 0;
  color: #fff;
  text-align: center;
}

.login-box .user-box {
  position: relative;
}

.login-box .user-box input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
}
.login-box .user-box label {
  position: absolute;
  top:0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  pointer-events: none;
  transition: .5s;
}

.login-box .user-box input:focus ~ label,
.login-box .user-box input:valid ~ label {
  top: -20px;
  left: 0;
  color: #90caf9;
  font-size: 12px;
}

.login-box form a {
  position: relative;
  display: flex;
  padding: 10px 20px;
  color: #f44336;
  font-size: 20px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: .5s;
  margin-top: 40px;
  letter-spacing: 4px
}

.login-box a:hover {
  background: #f44336;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #e57373,
              0 0 25px #ef5350,
              0 0 50px #f44336,
              0 0 100px #e53935;
              font-size: 20px;
}

.login-box a span {
  position: absolute;
  display: block;
}

.login-box a span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #f44336);
  animation: btn-anim1 1s linear infinite;
}

@keyframes btn-anim1 {
  0% {
    left: -100%;
  }
  50%,100% {
    left: 100%;
  }
}

.login-box a span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #f44336);
  animation: btn-anim2 1s linear infinite;
  animation-delay: .25s
}

@keyframes btn-anim2 {
  0% {
    top: -100%;
  }
  50%,100% {
    top: 100%;
  }
}

.login-box a span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #f44336);
  animation: btn-anim3 1s linear infinite;
  animation-delay: .5s
}

@keyframes btn-anim3 {
  0% {
    right: -100%;
  }
  50%,100% {
    right: 100%;
  }
}

.login-box a span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #f44336);
  animation: btn-anim4 1s linear infinite;
  animation-delay: .75s
}

@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }
  50%,100% {
    bottom: 100%;
  }
}

string {
  display: flex;
  flex-direction: column;
  text-align: center;
  animation: move 4s infinite;
}

.greeting {
  position: relative;
  top: 8.6vmin;
  animation: white-out 5s infinite;
}

.closure::after {
  content: '';
  position: absolute;
  height: 25vmin;
  width: 40vmin;
  background: #282c34;
  transform: translate(-45vmin, -24.5vmin);
}

.closure::before {
  content: '';
  position: absolute;
  height: 25vmin;
  width: 40vmin;
  background: #282c34;
  transform: translate(-40vmin, 5vmin);
}

.en {
  color: #fa8231;
}

.es {
  color: white;
}

.de {
  color: #c678dd;
}

.it {
  color: #a9b0bd;
}

@keyframes move {
  25% {
    transform: translatey(-5.8vmin);
    opacity: 1;
  }
  50% {
    transform: translatey(-11vmin);
  }
  75% {
    transform: translatey(-16.5vmin);
  }
}

.main_heading {
  color:"white";
  margin-top:0+"%";
  text-transform: uppercase;
  font-family: verdana;
  font-size: 10em;
  font-weight: 700;
  color: #f5f5f5;
  text-shadow: 1px 1px 1px #919191,
      1px 2px 1px #919191,
      1px 3px 1px #919191,
      1px 4px 1px #919191,
      1px 5px 1px #919191,
      1px 6px 1px #919191,
      1px 7px 1px #919191,
      1px 8px 1px #919191,
      1px 9px 1px #919191,
      1px 10px 1px #919191,
  1px 18px 6px rgba(16,16,16,0.4),
  1px 22px 10px rgba(16,16,16,0.2),
  1px 25px 35px rgba(16,16,16,0.2),
  1px 30px 60px rgba(16,16,16,0.4);
}


/* HTML: <div class="loader"></div> */
/* HTML: <div class="loader"></div> */
.loader {
  display: inline-flex;
  gap: 10px;
}
.loader:before,
.loader:after {
  content: "";
  height: 20px;
  aspect-ratio: 1;
  border-radius: 50%;
  background:
    linear-gradient(#000 0 0) top/100% 40% no-repeat,
    radial-gradient(farthest-side,#000 95%,#0000) 50%/8px 8px no-repeat
    #fff;
  animation: l7 1.5s infinite alternate ease-in;
}
@keyframes l7 {
  0%,
  70% {background-size:100% 40%,8px 8px}
  85% {background-size:100% 120%,8px 8px}
  100% {background-size:100% 40%,8px 8px}
}

.responsive-component-even {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  justify-content: center;
  width: 60%;
margin-top: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    padding-left: 5%;
    padding-right: 5%;
    width: 100vw;
    align-items: center;
  }
}

.responsive-component-odd {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 20px;
  justify-content: center;
  width: 60%;
  margin-top: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    padding-left: 5%;
    padding-right: 5%;
    width: 100vw;
    align-items: center;
  }
}


.image-container-even {
  flex: 1;
  margin-right: 0px;
  box-shadow: none;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.image-container-odd {
  flex: 1;
  margin-right: 0px;
  box-shadow: none;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.responsive-image {
  max-width: 50%;
  height: auto;
  margin-right: 50%;
  @media (max-width: 768px) {
    max-width: 60%;
    margin-left: 20%; /* Adjust this value as needed */
  }
}

.responsive-image-odd {
  max-width: 50%;
  height: auto;
  margin-left: 50%;
  @media (max-width: 768px) {
    max-width: 60%;
    margin-left: 20%; /* Adjust this value as needed */
  }
}

.content-container {
  flex: 1;
}

.title {
  font-size: 2.5rem;
  margin-bottom: 10px;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  color: #7f388b;

  @media (max-width: 768px) {
    font-size: 1.2em;
    text-align: center;
    font-family:'Times New Roman', Times, serif;
    font-weight: bold;

  }
}

.description {
  font-size: 1.55rem;
  line-height: 2;

  @media (max-width: 768px) {
    font-size: 1em;
    line-height: 1.3;
    padding: 10px;
    padding-left: 25px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}


.animated-feature {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 100px 0;
  overflow: hidden;
}

.animated-feature.reverse {
  flex-direction: row-reverse;
}

.animated-feature .image-container,
.animated-feature .content-container {
  flex: 1;
  opacity: 0;
  transform: translateX(-50px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.animated-feature.reverse .image-container,
.animated-feature.reverse .content-container {
  transform: translateX(50px);
}

.animated-feature.in-view .image-container,
.animated-feature.in-view .content-container {
  opacity: 1;
  transform: translateX(0);
}

.animated-feature .responsive-image {
  max-width: 100%;
  height: auto;
}

.animated-feature .title {
  font-size: 36px;
  margin-bottom: 20px;
}

.animated-feature .description {
  font-size: 18px;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .animated-feature {
    flex-direction: column;
    padding: 50px 0;
  }

  .animated-feature.reverse {
    flex-direction: column;
  }

  .animated-feature .image-container,
  .animated-feature .content-container {
    width: 100%;
    margin-bottom: 30px;
  }
}