.pie-chart-container {
    padding: 16px;
    border-radius: 10px;
    background-color: #232B5D;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .pie-chart-title {
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .pie-chart-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .legend-container {
    display: flex;
    flex-direction: column;
    margin-left: 0px;
  }
  
  .legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .legend-color {
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 10px;
  }
  
  .legend-text {
    font-size: 14px;
    font-weight: bold;
    color: black;
  }
  