.chart-container {
    padding: 20px;
    border-radius: 20px;
    background-color: #232B5D;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .chart-title {
    color: white;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
  }
  