/* Login.css */
.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #000;
  }
  
  .login-container {
    background-color: #000;
    padding: 4rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    border-color: red;
    border-width: 1px;
    border-style:solid;
  }
  
  .header {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .logo {
    max-width: 200px;
    height: auto;
  }
  
  .form-group {
    margin-bottom: 1.5rem;
  }
  
  .form-label {
    font-weight: 600;
  }
  
  .form-control {
    border-radius: 4px;
  }
  
  .submit-button {
    width: 100%;
    margin-top: 1rem;
    padding: 0.75rem;
    font-size: 1rem;
    font-weight: 600;
    background-color: #7f388b;
  }
  
  .alert {
    margin-bottom: 1rem;
  }
  
  .spinner-border {
    margin-right: 0.5rem;
  }
  
  @import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,800);

figure.snip1157 {
  font-family: 'Raleway', Arial, sans-serif;
  position: relative;
  overflow: hidden;
  margin: 10px;
  min-width: 220px;
  max-width: 310px;
  width: 100%;
  color: #333;
  height: 250px;
  text-align: left;
  box-shadow: none !important;
}

figure.snip1157 * {
  box-sizing: border-box;
  transition: all 0.35s cubic-bezier(0.25, 0.5, 0.5, 0.9);
  
}

figure.snip1157 img {
  max-width: 100%;
  vertical-align: middle;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin: 40px 0 0 10px;
  margin-left: 40px;
  margin-top: 50px;
}

figure.snip1157 blockquote {
  display: flex;
  border-radius: 8px;
  position: relative;
  background-color: #fafafa;
  padding: 25px 50px 30px 50px;
  font-size: 0.8em;
  font-weight: 500;
  height: 160px;
  justify-content: center;
  align-items: center;
  margin: 0;
  line-height: 1.6em;
  font-family:Arial, Helvetica, sans-serif;
}

figure.snip1157 blockquote:before,
figure.snip1157 blockquote:after {
  font-family: 'FontAwesome';
  content: "\201C";
  position: absolute;
  font-size: 50px;
  opacity: 0.3;
  font-style: normal;
}

figure.snip1157 blockquote:before {
  top: 25px;
  left: 20px;
}

figure.snip1157 blockquote:after {
  content: "\201D";
  right: 20px;
  bottom: 0;
}

figure.snip1157 .arrow {
  top: 100%;
  width: 0;
  height: 0;
  border-left: 0 solid transparent;
  border-right: 25px solid transparent;
  border-top: 25px solid #fafafa;
  margin: 0;
  position: absolute;
}

figure.snip1157 .author {
  position: absolute;
  bottom: 20px;
  padding: 0 50px 0 60px;
  margin: 0;
  text-transform: uppercase;
  color: #ffffff;
  transform: translateY(50%);
}

figure.snip1157 .author h5 {
  opacity: 0.8;
  margin: 0;
  font-weight: 800;
  margin-left: 30px;
}

figure.snip1157 .author h5 span {
  font-weight: 400;
  text-transform: none;
  font-size: 12px;
  padding-left: 2px;
}
